import React, { useEffect } from 'react';
import useResponsive from 'hooks/useResponsive';
import LoadingSpinner from 'component/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { useHairData } from 'hooks/style/create/useHairData';
import { useHairColors } from 'hooks/style/create/useHairsColors';
import {
  Alert, Box, Modal, Tooltip, 
} from '@mui/material';
import ConfirmModal from 'component/modal/ConfirmModal';
import UndoIcon from '@mui/icons-material/Undo';
import { useHairSelectionAndPreviews } from 'hooks/style/create/useHairSelectionAndPreviews';
import * as S from 'views/avatar/style/create/hair/HairStyle';
import HairSelection from 'views/avatar/style/create/hair/HairSelection/HairSelection';
import HairColorSelection from 'views/avatar/style/create/hair/HairColorSelection/HairColorSelection';
import { MobileHairPositions, MobileConfirmButton, MobileBackButton } from '../styles';

// Définition du type pour les parties de cheveux
type HairPart = 'front' | 'back' | 'top' | 'rear' | 'full' | '';

type CoreHairProps = {
  setHairColors: React.Dispatch<React.SetStateAction<any>> | null;
  setHair: React.Dispatch<React.SetStateAction<any>> | null;
  changeStepHairToStyle: () => void;
};

/**
 * Version autonome du composant Hair qui ne dépend pas de l'existence d'un idPicture3D
 * Ce composant peut être utilisé dans n'importe quel contexte où on veut simplement
 * sélectionner des éléments de style pour les cheveux.
 */
const CoreHair: React.FC<CoreHairProps> = ({
  setHairColors,
  setHair,
  changeStepHairToStyle,
}) => {
  const responsive = useResponsive();
  const { t } = useTranslation();
  const [hairPartStep, setHairPartStep] = React.useState<HairPart>('');
  const { hairs, hairsColors, status } = useHairData();
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

  /* manage hair selection and provide preview of the selected hair */
  const { hairSelected, setHairSelected, hairPreviewURL } = useHairSelectionAndPreviews(hairs);

  /* manage hair colors provide states */
  const {
    meshColor,
    setMeshColor,
    endColor,
    setEndColor,
    hairColor,
    setHairColor,
  } = useHairColors(hairsColors);

  // Confirmation et envoi des données
  const confirmHair = () => {
    const hairColorsChoosen = {
      mesh: meshColor,
      end: endColor,
      hair: hairColor,
    };

    if (setHair) {
      /* hair handle */
      if (hairSelected.full) {
        setHair([hairSelected.full]);
      } else {
        setHair([
          hairSelected.back,
          hairSelected.front,
          hairSelected.rear,
          hairSelected.top,
        ]);
      }
    }
    /* hair colors handling */
    if (setHairColors) {
      setHairColors(Object.values(hairColorsChoosen));
    }
  };

  // Création d'un style pour les pins sur mobile
  const mobileHairPinStyle = responsive.isMobile ? {
    cursor: 'pointer',
    width: '2rem',
    height: '2rem', 
    backgroundColor: 'rgba(100, 100, 255, 0.8)',
    border: '3px solid white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.6)',
    zIndex: 100,
    position: 'absolute' as const,
  } : { cursor: 'pointer' };

  // Création du composant de sélection de la partie de cheveux
  const HairPartSelection = (
    <S.HairContainer size={responsive}>
      {openConfirmModal ? (
        <ConfirmModal
          title={t('avatar.style.confirm')}
          body={(
            <Alert
              severity="warning"
              style={{ fontFamily: 'Urbanist' }}
            >
              {t('avatar.style.warning')}
            </Alert>
          )}
          onConfirm={() => confirmHair()}
          onCancel={() => setOpenConfirmModal(false)}
        />
      ) : null}
      {status === 'loading' ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <>
          <S.HairPreviewContainer size={responsive}>
            <S.HairPreview
              src={hairPreviewURL}
              size={responsive}
            />
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={hairs.front.libel}
            >
              <S.FrontHair
                size={responsive}
                onClick={() => setHairPartStep('front')}
                style={{ cursor: 'pointer' }}
                className="hair-part-pin"
              />
            </Tooltip>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={hairs.back.libel}
            >
              <S.BackHair
                size={responsive}
                onClick={() => setHairPartStep('back')}
                style={{ cursor: 'pointer' }}
                className="hair-part-pin"
              />
            </Tooltip>

            <Tooltip
              disableFocusListener
              disableTouchListener
              title={hairs.top.libel}
            >
              <S.TopHair
                size={responsive}
                onClick={() => setHairPartStep('top')}
                style={{ cursor: 'pointer' }}
                className="hair-part-pin"
              />
            </Tooltip>

            <Tooltip
              disableFocusListener
              disableTouchListener
              title={hairs.rear.libel}
            >
              <S.RearHair
                size={responsive}
                onClick={() => setHairPartStep('rear')}
                style={{ cursor: 'pointer' }}
                className="hair-part-pin"
              />
            </Tooltip>

            <Tooltip
              disableFocusListener
              disableTouchListener
              title={hairs.full.libel}
            >
              <S.FullHair
                size={responsive}
                onClick={() => setHairPartStep('full')}
                style={{ cursor: 'pointer' }}
                className="hair-part-pin"
              />
            </Tooltip>
            <MobileBackButton>
              <UndoIcon onClick={() => changeStepHairToStyle()} />
            </MobileBackButton>
            <MobileConfirmButton
              style={{
                margin: '0',
                position: 'absolute',
                top: '2vh',
                right: '3vw',
                zIndex: 10,
              }}
              disabled={false}
              onClick={() => setOpenConfirmModal(true)}
            >
              {t('global.confirm')}
            </MobileConfirmButton>
          </S.HairPreviewContainer>
          {hairColor && (
            <HairColorSelection
              hairColors={hairsColors}
              stateHairColor={[hairColor, setHairColor]}
              stateMeshColor={[meshColor, setMeshColor]}
              stateEndColor={[endColor, setEndColor]}
            />
          )}
        </>
      )}
    </S.HairContainer>
  );

  if (hairPartStep === '') {
    return HairPartSelection;
  }

  return (
    <HairSelection
      selections={[hairSelected, setHairSelected]}
      hairs={hairs[hairPartStep]}
      step={hairPartStep}
      setStep={setHairPartStep}
    />
  );
};

export default CoreHair;

import styled from 'styled-components';
import COLORS from 'color';
import { ScreenSize } from 'type/ScreenSize';

export const SliderButton = styled.div<{ size: ScreenSize }>`
  cursor: pointer;
  user-select: none;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
  font-size: ${({ size }) => (size.isDesktop ? '5vmin' : '10vmin')};
  color: ${COLORS.BLUE_SILKKE};
  font-weight: 900;
  
  @media screen and (max-width: 428px) {
    font-size: 8vmin;
    align-self: center;
  }
  
  @media screen and (max-width: 390px) {
    font-size: 7vmin;
  }
`;

export const Palette = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  
  @media screen and (max-width: 428px) {
    margin: 0 auto;
    padding: 0 0.3rem;
    width: 100%;
    max-width: 100%;
  }
`;

export const ColorWrapper = styled.div`
  display: flex;
  width: auto;
  justify-content: center;
  
  @media screen and (max-width: 428px) {
    justify-content: center;
    flex: 1;
    margin: 0 auto;
  }
`;

export const ColorPin = styled.div<{
  color: string;
  selected: boolean;
  size: ScreenSize;
}>`
  cursor: pointer;
  user-select: none;
  background: ${({ color }) => color};
  width: ${({ size }) => (size.isDesktop ? '1vmin' : '6vmin')};
  height: ${({ size }) => (size.isDesktop ? '1vmin' : '6vmin')};
  border-radius: 50%;
  margin: 0 1rem;
  border: 2px solid transparent;
  ${({ selected }) => selected && `border: 3px solid ${COLORS.BLUE_SILKKE};`}
  
  @media screen and (max-width: 428px) {
    width: 5vmin;
    height: 5vmin;
    margin: 0 0.8rem;
  }
  
  @media screen and (max-width: 390px) {
    width: 4.5vmin;
    height: 4.5vmin;
    margin: 0 0.6rem;
  }
`;

export const ImageColor = styled.div<{
  url: string;
  size: ScreenSize;
  selected: boolean;
}>`
  width: 5rem;
  height: 5rem;
  ${({ selected }) => selected && `border: 3px solid ${COLORS.BLUE_SILKKE};`}
  border-radius: 50%;
  overflow: hidden; /* Cache les parties de l'image qui débordent */
  background-image: url(${({ url }) => url});
  background-position: 50% 10%;
  cursor: pointer;
  user-select: none;
  background-size: 300%;
  
  @media screen and (max-width: 428px) {
    width: 4rem;
    height: 4rem;
  }
  
  @media screen and (max-width: 390px) {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

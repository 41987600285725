import styled, { createGlobalStyle } from 'styled-components';
import COLORS from 'color';

// Styles globaux pour les pins de sélection des cheveux
export const GlobalPinStyles = createGlobalStyle`
  .hair-part-pin {
    @media screen and (max-width: 428px) {
      width: 1.8rem !important;
      height: 1.8rem !important;
      background-color: rgba(100, 100, 255, 0.8) !important;
      border: 3px solid white !important;
      z-index: 100 !important;
      transform: scale(1.2);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5) !important;
    }
    
    @media screen and (max-width: 375px) {
      width: 1.6rem !important;
      height: 1.6rem !important;
      border: 3px solid white !important;
    }
  }
`;

export const StyledOverlay = styled.div<{
  isMobile: boolean;
  customStyle?: React.CSSProperties;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: ${COLORS.GAINSBORO};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? '0' : '1rem')};
  touch-action: ${({ isMobile }) => (isMobile ? 'manipulation' : 'auto')};

  /* Styles supplémentaires pour mobile */
  ${({ isMobile }) => isMobile
    && `
    @media screen and (max-width: 414px) {
      img {
        max-height: 70vh;
        object-fit: contain;
      }
      
      button {
        padding: 0.4rem 0.8rem;
        font-size: 0.85rem;
      }
    }
    
    @media screen and (max-width: 375px) {
      button {
        padding: 0.3rem 0.6rem;
        font-size: 0.8rem;
      }
    }
    `}
`;

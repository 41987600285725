import styled from 'styled-components';
import COLORS from 'color';
import { ScreenSize } from 'type/ScreenSize';

export const EyesBuilderContainer = styled.div<{ size: ScreenSize }>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  position: relative;
  ${({ size }) => size.isDesktop && 'width: 50%;'}
  ${({ size }) => size.isDesktop && 'margin: 0 auto;'}
`;

export const EyesBuilderPreview = styled.img<{ size: ScreenSize }>`
  ${({ size }) => (size.isDesktop ? 'max-width: 70%;' : 'max-width: 100%;')}
  ${({ size }) => (size.isDesktop ? 'max-height: 70%;' : 'max-height: 100%;')}
  display:block;
  ${({ size }) => size.isDesktop && 'margin: 0 auto;'}
  ${({ size }) => size.isMobile && 'height: 100%;'}
  
  @media screen and (max-width: 428px) {
    object-fit: contain;
    max-height: 78vh;
  }
  
  @media screen and (max-width: 414px) {
    object-fit: contain;
    max-height: 75vh;
  }
  
  @media screen and (max-width: 375px) {
    max-height: 70vh;
  }
`;

export const EyesBuilderActions = styled.div<{ size: ScreenSize }>`
  position: fixed;
  bottom: 0;
  padding: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  ${({ size }) => (size.isDesktop ? '' : 'min-height: 5rem;')}
  ${({ size }) => (size.isDesktop ? '' : 'width: 100%;')}
  border-radius: 20px 20px 0 0;
  justify-content: center;
  display: flex;
  align-items: center;
  background: ${COLORS.GAINSBORO};
  
  @media screen and (max-width: 428px) {
    padding: 0.5rem 0.3rem;
    flex-wrap: wrap;
    gap: 0.3rem;
    /* Safari fix pour iPhone 12 Pro Max */
    @supports (-webkit-touch-callout: none) {
      padding-bottom: env(safe-area-inset-bottom, 0.5rem);
      bottom: 0;
    }
  }
  
  @media screen and (max-width: 414px) {
    padding: 0.4rem 0.2rem;
    flex-wrap: wrap;
    gap: 0.3rem;
    /* Safari fix position */
    @supports (-webkit-touch-callout: none) {
      bottom: 0;
    }
  }
  
  @media screen and (max-width: 375px) {
    padding: 0.3rem 0.1rem;
    min-height: 4rem;
  }
`;

export const EyesBuilderAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 428px) {
    margin: 0 0.3rem;
    flex: 0 0 auto;
  }
  
  @media screen and (max-width: 414px) {
    margin: 0 0.2rem;
  }
`;

export const ToggleButton = styled.button`
  border: 1px solid ${COLORS.BLUE_SILKKE};
  border-radius: 20px;
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.BLUE_SILKKE};
  
  @media screen and (max-width: 428px) {
    padding: 0.35rem 1rem;
    font-size: 0.9rem;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 414px) {
    padding: 0.3rem 0.8rem;
    font-size: 0.85rem;
  }
  
  @media screen and (max-width: 375px) {
    padding: 0.2rem 0.6rem;
    font-size: 0.8rem;
  }
`;

export const EyesBuilderActionLabel = styled.div<{ size: ScreenSize }>`
  min-width: 3rem;
  border-radius: 10px;
  font-family: "Urbanist", sans-serif;
  padding: 2%;
  text-align: center;
  color: ${COLORS.BLUE_SILKKE};
  
  @media screen and (max-width: 428px) {
    min-width: 2.2rem;
    font-size: 0.95rem;
  }
  
  @media screen and (max-width: 414px) {
    min-width: 2rem;
    font-size: 0.9rem;
  }
`;

export const EyesBuilderShade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    /* Cible tous les div enfants directement */
    flex: 1; /* Donne une flexibilité égale à tous les enfants */
  }
  
  @media screen and (max-width: 428px) {
    width: 100%;
    justify-content: center;
  }
  
  @media screen and (max-width: 414px) {
    width: 100%;
  }
`;

export const NextButton = styled.button`
  border: none;
  border-radius: 10px;
  display: block;
  padding: 0.6rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: "Urbanist", sans-serif;
  font-size: 1rem;
  color: ${COLORS.BLUE_SILKKE};
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e0e0e0; // Couleur de fond au survol
  }
  
  @media screen and (max-width: 428px) {
    padding: 0.45rem 1.3rem;
    font-size: 0.95rem;
    position: fixed;
    right: 0.8rem;
    top: 0.8rem;
    z-index: 100;
    background-color: white;
  }
  
  @media screen and (max-width: 414px) {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
    position: fixed;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 100;
    background-color: white;
  }
  
  @media screen and (max-width: 375px) {
    padding: 0.3rem 1rem;
    font-size: 0.8rem;
  }
`;

import styled from 'styled-components';
import Button from 'component/Button';
import * as S from 'views/avatar/style/create/eyes/EyesStyle';

// Style supplémentaire pour mobile
export const MobilePreviewText = styled(S.PreviewTextCoveringImage)`
  @media screen and (max-width: 414px) {
    font-size: 1.75rem;
    width: 80%;
  }

  @media screen and (max-width: 375px) {
    font-size: 1.5rem;
    width: 85%;
  }
`;

export const MobileButton = styled(Button)`
  @media screen and (max-width: 414px) {
    width: auto !important;
    height: auto !important;
    padding: 0.6rem 1.5rem !important;
    margin-top: 1rem !important;
  }
`;

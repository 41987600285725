import COLORS from 'color';
import styled, { css } from 'styled-components';
import { ScreenSize } from 'type/ScreenSize';

// Import des styles d'amélioration pour mobile
import { enhanceMobilePins } from '../../../../../components/BuildYourStyle/styles/CoreHair.styles';

export const HairContainer = styled.div<{ size: ScreenSize }>`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${COLORS.GAINSBORO};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const HairPreviewContainer = styled.div<{ size: ScreenSize }>`
  display: block;
  height: 100%;
  width: ${({ size }) => (size.isDesktop ? '50%' : '100%')};
  margin: 0 auto;
  position: relative;
`;

export const HairPreview = styled.img<{ size: ScreenSize }>`
  object-fit: ${({ size }) => (size.isMobile ? 'cover' : 'contain')};
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: block;
  margin: 0 auto;
  
  @media screen and (max-width: 428px) {
    object-fit: contain;
    max-height: 85vh;
  }
  
  @media screen and (max-width: 414px) {
    max-height: 80vh;
  }
`;

const HairPosition = styled.span<{ size: ScreenSize }>`
  font-family: "Urbanist", sans-seris;
  font-weight: bold;
  font-size: 1rem;
  background-color: ${COLORS.GREY};
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  cursor: pointer;
  color: ${COLORS.GREY};
  position: absolute;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  
  @media screen and (max-width: 428px) {
    width: 1.8rem !important;
    height: 1.8rem !important;
    background-color: rgba(100, 100, 255, 0.8) !important;
    border: 3px solid white !important;
    z-index: 10 !important;
    transform: scale(1.2);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5) !important;
  }
  
  @media screen and (max-width: 375px) {
    width: 1.6rem !important;
    height: 1.6rem !important;
    border: 3px solid white !important;
  }
`;

export const FrontHair = styled(HairPosition)<{ size: ScreenSize }>`
  top: 45vh;
  right: ${({ size }) => (size.isMobile ? '13vw' : size.isTablet ? '28vw' : '17vw')};
  
  @media screen and (max-width: 428px) {
    top: 45vh;
    right: 15vw;
  }
`;

export const RearHair = styled(HairPosition)<{ size: ScreenSize }>`
  top: ${({ size }) => (size.isTablet ? '45vh' : '45vh')};
  left: ${({ size }) => (size.isMobile ? '20vw' : size.isTablet ? '28vw' : '17vw')};
  
  @media screen and (max-width: 428px) {
    top: 45vh;
    left: 15vw;
  }
`;

export const TopHair = styled(HairPosition)<{ size: ScreenSize }>`
  top: ${({ size }) => (size.isTablet ? '33vh' : '28vh')};
  right: ${({ size }) => (size.isMobile ? '13vw' : size.isTablet ? '28vw' : '17vw')};
  
  @media screen and (max-width: 428px) {
    top: 30vh;
    right: 15vw;
  }
`;

export const BackHair = styled(HairPosition)<{ size: ScreenSize }>`
  top: ${({ size }) => (size.isTablet ? '30vh' : '30vh')};
  left: ${({ size }) => (size.isMobile ? '13vw' : size.isTablet ? '28vw' : '17vw')};
  
  @media screen and (max-width: 428px) {
    top: 30vh;
    left: 15vw;
  }
`;

export const FullHair = styled(HairPosition)<{ size: ScreenSize }>`
  top: ${({ size }) => (size.isTablet ? '35vh' : '35vh')};
  left: ${({ size }) => (size.isMobile ? '50vw' : size.isTablet ? '42vw' : '23vw')};
  
  @media screen and (max-width: 428px) {
    top: 35vh;
    left: 45vw;
  }
`;

export const ConfirmButton = styled.button`
  border: none;
  border-radius: 10px;
  display: block;
  padding: 0.6rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: "Urbanist", sans-serif;
  font-size: 1rem;
  color: ${COLORS.BLUE_SILKKE};
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #e0e0e0; // Couleur de fond au survol
  }
`;

/* modal confirm  */

export const ModalContainer = styled.div<{ size: ScreenSize }>`
  width: ${({ size }) => (size.isMobile ? '85%' : 'auto')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
`;

export const ModalActions = styled.div`
  display: flex;
`;

export const ModalBody = styled.div`
  font-family: "Urbanist", sans-serif;
  text-align: center;
  padding: 1rem;
  color: ${COLORS.BLUE_SILKKE};
`;

export const ModalConfirmButton = styled.button`
  border: none;
  border-radius: 10px;
  display: block;
  padding: 0.6rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: "Urbanist", sans-serif;
  font-size: 1rem;
  color: ${COLORS.BLUE_SILKKE};
  cursor: pointer;
  transition: background-color 0.3s ease;

  background: transparent;
  &:hover {
    background-color: ${COLORS.BLUE_SILKKE}; // Couleur de fond au survol
    color: white;
  }
`;

export const ModalCancelButton = styled.button`
  border: none;
  border-radius: 10px;
  background: transparent;
  display: block;
  padding: 0.6rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: "Urbanist", sans-serif;
  font-size: 1rem;
  color: red;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: red; // Couleur de fond au survol
    color: white;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 2vh;
  left: 3vw;
  z-index: 10;
  border: none;
  border-radius: 10px;
  background: ${COLORS.GAINSBORO};
  display: block;
  padding: 0.3rem 1rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  font-family: "Urbanist", sans-serif;
  font-size: 1rem;
  color: ${COLORS.BLUE_SILKKE};
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

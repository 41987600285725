import styled from 'styled-components';
import * as S from 'views/avatar/style/create/hair/HairStyle';

// Composants styled pour améliorer la responsivité mobile
export const MobileHairPositions = styled.div`
  @media screen and (max-width: 414px) {
    transform: scale(1.2);
  }

  @media screen and (max-width: 375px) {
    transform: scale(1.1);
  }
`;

export const MobileConfirmButton = styled(S.ConfirmButton)`
  @media screen and (max-width: 414px) {
    padding: 0.5rem 1rem !important;
    top: 1vh !important;
    right: 2vw !important;
    font-size: 0.9rem !important;
  }

  @media screen and (max-width: 375px) {
    padding: 0.4rem 0.8rem !important;
    font-size: 0.8rem !important;
  }
`;

export const MobileBackButton = styled(S.BackButton)`
  @media screen and (max-width: 414px) {
    top: 1vh !important;
    left: 2vw !important;
  }
`;

// Exporter la constante manquante pour résoudre l'erreur d'import circulaire
export const enhanceMobilePins = `
  @media screen and (max-width: 428px) {
    width: 1.8rem !important;
    height: 1.8rem !important;
    background-color: rgba(100, 100, 255, 0.8) !important;
    border: 3px solid white !important;
    z-index: 10 !important;
    transform: scale(1);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5) !important;
  }
  
  @media screen and (max-width: 375px) {
    width: 1.6rem !important;
    height: 1.6rem !important;
    border: 3px solid white !important;
  }
`;
